import { getExpenseRatio, isEmpty, formatNumber } from './utilities';

const frequencyLabels = {
  ThreeMonth: '3 Months',
  SixMonth: '6 Months',
  OneYear: '1 Year',
  SixYear: '6 Years'
};

const refAssetLabels = {
  SPX: 'S&P 500',
  NDX: 'Nasdaq',
  RTY: 'Russell 2000',
  MXEA: 'MSCI EAFE'
};

const downsideLabels = {
  Buffer: 'Buffer',
  Floor: 'Floor',
  Par: 'Downside Participation'
};

const upsideLabels = {
  Spread: 'Spread',
  Cap: 'Cap',
  Par: 'Upside Participation',
  Trigger: 'Trigger'
};

const downsideStrategyZoneLabels = {
  primary: {
    capgroup: {
      Buffer: {
        '-3': 'Below Buffer Range',
        '-2': 'Within Buffer',
        '-1': 'Downside Before Buffer'
      }
    },
    milliman: {
      Buffer: {
        '-2': 'Below Buffer Range',
        '-1': 'Within Buffer'
      },
      Floor: {
        '-2': 'Floor Reached',
        '-1': 'Downside Before Floor'
      },
      Par: {
        '-1': 'Downside Participation'
      }
    }
  },
  secondary: {
    milliman: {
      Buffer: {
        '-1': 'Below Contribution Range'
      }
    }
  }
};
  
const upsideStrategyZoneLabels = {
    Spread: {
      '1': 'Partial Spread Remaining',
      '2': 'Upside Above Spread'
    },
    Cap: {
      '1': 'Upside Below Cap',
      '2': 'Capped Upside'
    },
    Par: {
      '1': 'Upside Participation'
    },
    Trigger: {
      '1': 'Above Trigger'
    }
};

function getStrategyZoneLabel(strategyZone, fund) {
  const strategyZoneLabels = strategyZone < 0 ? downsideStrategyZoneLabels.primary[fund.strategySeries][fund.downside] : upsideStrategyZoneLabels[fund.upside];
  return strategyZoneLabels ? strategyZoneLabels[strategyZone] || '-' : '';
}

function getSecondaryStrategyZoneLabel(strategyZone, fund) {
  const strategyZoneLabels = strategyZone < 0 ? downsideStrategyZoneLabels.secondary[fund.strategySeries][fund.downside] : upsideStrategyZoneLabels[fund.upside];
  return strategyZoneLabels ? strategyZoneLabels[strategyZone] || '-' : '';
}

function getStrategyZoneLabelsByDownside(refAsset, downside, series) {
  return Object.keys(downsideStrategyZoneLabels[refAsset][series][downside]).map(k => downsideStrategyZoneLabels[refAsset][series][downside][k]);
}

function getStrategyZoneLabelsByUpside(upside) {
  return Object.keys(upsideStrategyZoneLabels[upside]).map(k => upsideStrategyZoneLabels[upside][k]);
}

function getFundStatsColumns(downside, upside) {
  const columns = [
    'fundNav',
    'fundReturn',
    'referenceAssetReturn',
    'fixedIncomeContribution',
    'strategyZone',
    'remainingDownsideCurrent'
  ];

  if (downside === 'Buffer' && upside !== 'Trigger') columns.push('remainingDownsideBeforeCurrent');
  if (upside === 'Spread' || upside === 'Trigger') columns.push('remainingUpsideBeforeCurrent');

  columns.push(
    'remainingUpsideCurrent',
    'remainingTimeValueCurrent',
    'outcomePeriodDaysRemaining'
  );

  return columns;
}

function getTableColumns(downside, upside, series, inclEquityColumn = false) {
  const columns = [
    'resetMonth',
    'fundNav',
    'fundReturn',
    'referenceAssetReturn'
  ];

  if (inclEquityColumn)
    columns.push('equity');

  if (series === 'milliman')
    columns.push('fixedIncomeContribution');

  columns.push(
    'strategyZone',
    'remainingDownsideCurrent'
  );

  if (downside === 'Buffer' && upside !== 'Trigger') columns.push('remainingDownsideBeforeCurrent');
  if (upside === 'Spread' || upside === 'Trigger') columns.push('remainingUpsideBeforeCurrent');

  columns.push(
    'remainingUpsideCurrent',
    'remainingTimeValueCurrent',
    'outcomePeriodDaysRemaining'
  );

  return columns;
}

function getStatLabels(client, refAsset2, downside, upside, series, type = 'current', splitStrategy = false) {
  const labels = {
    resetMonth: 'Reset Month',
    fundReturn: 'Fund Return',
    referenceAssetReturn: series === 'milliman' ? `Index Return${!isEmpty(refAsset2) ? 's' : ''}` : 'Underlying Exposure Return',
    equity: 'Equity',
    fixedIncomeContribution: 'Fixed Income Contribution',
    remainingTimeValueCurrent: `${type !== 'initial' ? 'Remaining ' : ''}Option Premium / Discount`,
    outcomePeriodDaysRemaining: `${series === 'capgroup' ? 'Option Allocation' : 'Outcome Period'} Days${type !== 'initial' ? ' Remaining' : ''}`
  };

  if (!splitStrategy) {
    labels.strategyZone = `${series !== 'capgroup' ? 'Index ' : ''}Strategy Zone${!isEmpty(refAsset2) ? 's (Primary/Secondary)' : ''}`;
  }
  else {
    labels.strategyZone = `${!isEmpty(refAsset2) ? 'Primary ' : ''}${series !== 'capgroup' ? 'Index ' : ''}Strategy Zone`;
    labels.secondaryStrategyZone = `${!isEmpty(refAsset2) ? 'Secondary ' : ''}${series !== 'capgroup' ? 'Index ' : ''}Strategy Zone`;
  }

  if (client === 'aig')
    labels.fundNav = 'Fund AUV';
  else
    labels.fundNav = 'Fund NAV';

  if (downside === 'Par')
    labels.remainingDownsideCurrent = `${type !== 'initial' ? 'Remaining ' : ''}Downside Par Rate`;
  else if (downside === 'Floor')
    labels.remainingDownsideCurrent = `${type !== 'initial' ? 'Remaining ' : ''}Downside Before Floor`;
  else if (downside === 'Buffer') {
    labels.remainingDownsideCurrent = `${type !== 'initial' ? 'Remaining ' : ''}Buffer`;
    if (upside !== 'Trigger')
      labels.remainingDownsideBeforeCurrent = `${type !== 'initial' ? 'Remaining ' : ''}Downside Before Buffer`;
  }

  if (upside === 'Par')
    labels.remainingUpsideCurrent = `${type !== 'initial' ? 'Remaining ' : ''}Upside Par Rate`;
  else if (upside === 'Cap')
    labels.remainingUpsideCurrent = `${type !== 'initial' ? 'Remaining ' : ''}Cap`;
  else if (upside === 'Spread') {
    labels.remainingUpsideCurrent = `${type !== 'initial' ? 'Remaining ' : ''}Spread`;
    labels.remainingUpsideBeforeCurrent = `${type !== 'initial' ? 'Remaining ' : ''}Upside Before Spread`;
  }
  else if (upside === 'Trigger') {
    labels.remainingUpsideCurrent = `${type !== 'initial' ? 'Remaining ' : ''}Defined Return`;
    labels.remainingUpsideBeforeCurrent = `${type !== 'initial' ? 'Remaining ' : ''}Upside Before Trigger`;
  }

  return labels;
}

function getStatTooltips(client, refAsset, refAsset2, downside, upside, series, mergedStrategyZones) {
  var expenseRatio = getExpenseRatio(series);

  const tooltips = {
    resetMonth: `The month that the strategy establishes a new ${series === 'capgroup' ? 'option allocation' : 'outcome period'}`,
    fundReturn: `The calculated return of the ${client === 'aig' ? 'AUV' : 'NAV'} for the outcome since the start of the ${series === 'capgroup' ? 'option allocation' : 'outcome period'}, after fees and expenses.`,
    fixedIncomeContribution: `The anticipated yield of the portfolio holdings' exposure to fixed income is used to estimate the fund's overall investment strategy performance during the ${series === 'capgroup' ? 'Option Allocation' : 'Outcome Period'}. Due to this, risk in addition to the fund's equity exposure is introduced, and the strategy may not achieve its desired objective. The performance of the fixed income exposed investments in the portfolio is measured against the hypothetical performance of an investment matching the anticipated yield of the fixed income portfolio but without risk.`,
    strategyZone: `The section of the final payoff profile that the ${series === 'capgroup' ? 'equity basket' : 'index'} return is currently within (${getStrategyZoneLabelsByDownside('primary', downside, series).map(s => `"${s}"`).join(', ')}, ${getStrategyZoneLabelsByUpside(upside).map(s => `"${s}"`).join(', ')}` + 
                  `${!isEmpty(refAsset2) && mergedStrategyZones ? `, as well as ${getStrategyZoneLabelsByDownside('secondary', downside, series).map(s => `"${s}"`).join(', ')}, ${getStrategyZoneLabelsByUpside(upside).map(s => `"${s}"`).join(', ')}` : ''}).`,
    outcomePeriodDaysRemaining: `The number of days remaining until the end of the ${series === 'capgroup' ? 'option allocation' : 'outcome period'}.`
  };

  if (!isEmpty(refAsset2))
    tooltips.secondaryStrategyZone = `The section of the final payoff profile that the index return is currently within (${getStrategyZoneLabelsByDownside('secondary', downside, series).map(s => `"${s}"`).join(', ')}, ${getStrategyZoneLabelsByUpside(upside).map(s => `"${s}"`).join(', ')}).`;

  if (series === 'capgroup') {
    tooltips.referenceAssetReturn = `The calculated return of the underlying equity basket since the start of the ${series === 'capgroup' ? 'option allocation' : 'outcome period'}.`;
    tooltips.remainingTimeValueCurrent = 'The remaining premium/discount earned from equity options contracts, assuming underlying equity exposure unchanged';
  }
  else {
    tooltips.referenceAssetReturn = `The calculated return of the ${refAssetLabels[refAsset]}${!isEmpty(refAsset2) ? ' & ' + refAssetLabels[refAsset2] : ''} price ${!isEmpty(refAsset2) ? 'indices' : 'index'} since the start of the ${series === 'capgroup' ? 'option allocation' : 'outcome period'}.`;
    tooltips.remainingTimeValueCurrent = 'The remaining premium/discount earned from equity options contracts and fixed income holdings, assuming index(es) unchanged';
  }

  if (client === 'aig')
    tooltips.fundNav = `The Accumulated Unit Value of the fund that reflects the fund and M&E fees (${formatNumber(expenseRatio, 'percent')} annually) since the start of the ${series === 'capgroup' ? 'option allocation' : 'outcome period'}.`;
  else
    tooltips.fundNav = `The Net Asset Value of the fund that reflects the fund fees (${formatNumber(expenseRatio, 'percent')} annually) since the start of the ${series === 'capgroup' ? 'option allocation' : 'outcome period'}.`;

  if (downside === 'Par') {
    tooltips.downside = 'The initial equity participation rate the fund seeks to provide if the index return finishes in the "Downside Participation" zone. NOTE: To calculate remaining potential return outcomes, multiply the current/net remaining downside par rate with the potential index return and subract expenses.';
    tooltips.remainingDownsideCurrent = `The equity participation rate the fund seeks to provide if the index return remains in the "Downside Participation" zone. NOTE: To calculate remaining potential return outcomes, multiply the current/net "Remaining Downside Par Rate" with the potential index return and add current/net "Remaining Option Premium / Discount".`;
  }
  else if (downside === 'Floor') {
    tooltips.downside = 'The maximum potential equity loss the fund seeks to allow.';
    tooltips.remainingDownsideCurrent = "The amount of loss the fund's equity exposure would need to experience before reaching the floor.";
  }
  else if (downside === 'Buffer') {
    tooltips.downside = `The initial equity downside protection the fund seeks to provide for the full ${series === 'capgroup' ? 'option allocation' : 'outcome period.'}`;
    if (upside !== 'Trigger') {
      tooltips.remainingDownsideCurrent = "The remaining downside protection the fund seeks to provide for its equity exposure.";
      tooltips.remainingDownsideBeforeCurrent = "The amount of loss the fund's equity exposure would need to experience before reaching the beginning of the buffer range.";
    }
  }

  if (upside === 'Par') {
    tooltips.upside = 'The initial equity participation rate the fund seeks to provide if the index return finishes in the "Upside Participation" zone. NOTE: To calculate remaining potential return outcomes, multiply the current/net remaining upside par rate with the potential index return and subtract expenses.';
    tooltips.remainingUpsideCurrent = `The equity participation rate the fund seeks to provide if the index return remains in the "Upside Participation" zone. NOTE: To calculate remaining potential return outcomes, multiply the current/net "Remaining Upside Par Rate" with the potential index return and add current/net "Remaining Option Premium / Discount".`;
  }
  else if (upside === 'Cap') {
    tooltips.upside = `The cumulative maximum equity upside return the strategy seeks to provide for the full ${series === 'capgroup' ? 'option allocation' : 'outcome period'}.`;
    tooltips.remainingUpsideCurrent = `The cumulative maximum remaining upside return the strategy seeks to provide for its equity exposure until the end of the ${series === 'capgroup' ? 'option allocation' : 'outcome period'} if the ${series === 'capgroup' ? 'equity basket' : 'index'} caps are reached.`;
  }
  else if (upside === 'Spread') {
    tooltips.upside = `The initial index appreciation required for the fund's equity exposure to participate in upside gains.`;
    tooltips.remainingUpsideCurrent = "The remaining index appreciation required for the fund's equity exposure to participate in upside gains.";
    tooltips.remainingUpsideBeforeCurrent = "The remaining index appreciation required before the fund's equity exposure reaches the beginning of the spread range.";
  }
  else if (upside === 'Trigger') {
    tooltips.upside = `The fund equity return if the benchmark index finishes the ${series === 'capgroup' ? 'option allocation' : 'outcome period'} with a positive return.`;
    tooltips.remainingUpsideCurrent = `The fund equity return if the benchmark index finishes the ${series === 'capgroup' ? 'option allocation' : 'outcome period'} with a positive return.`;
    tooltips.remainingUpsideBeforeCurrent = "The remaining index appreciation required before the fund's equity exposure reaches the trigger level.";
  }

  if (client === 'aig')
    tooltips.expenseRatio = 'Expense Ratio includes fund and M&E fees.';
  else
    tooltips.expenseRatio = null;

  return tooltips;
}

function getTableColSpans(downside, upside) {
  const colSpans = {
    resetMonth: 1,
    fundNav: 1,
    fundReturn: 1,
    referenceAssetReturn: 1,
    equity: 1,
    fixedIncomeContribution: 1,
    strategyZone: 1,
    remainingTimeValueCurrent: 1,
    outcomePeriodDaysRemaining: 1
  };

  if (downside === 'Par')
    colSpans.remainingDownsideCurrent = 2;
  else if (downside === 'Floor')
    colSpans.remainingDownsideCurrent = 2;
  else if (downside === 'Buffer') {
    if (upside !== 'Trigger') {
      colSpans.remainingDownsideCurrent = 1;
      colSpans.remainingDownsideBeforeCurrent = 1;
    }
    else
      colSpans.remainingDownsideCurrent = 2;
  }

  if (upside === 'Par')
    colSpans.remainingUpsideCurrent = 2;
  else if (upside === 'Cap')
    colSpans.remainingUpsideCurrent = 2;
  else if (upside === 'Spread') {
    colSpans.remainingUpsideCurrent = 1;
    colSpans.remainingUpsideBeforeCurrent = 1;
  }
  else if (upside === 'Trigger') {
    colSpans.remainingUpsideCurrent = 1;
    colSpans.remainingUpsideBeforeCurrent = 1;
  }

  return colSpans;
}

const tableClasses = {
  resetMonth: 'text-start',
  fundNav: null,
  fundReturn: null,
  referenceAssetReturn: null,
  equity: null,
  fixedIncomeContribution: null,
  strategyZone: null,
  remainingDownsideCurrent: null,
  remainingDownsideBeforeCurrent: null,
  remainingUpsideBeforeCurrent: null,
  remainingUpsideCurrent: null,
  remainingTimeValueCurrent: null,
  outcomePeriodDaysRemaining: null
}

export {
  frequencyLabels,
  refAssetLabels,
  downsideLabels,
  upsideLabels,
  
  getStrategyZoneLabel,
  getSecondaryStrategyZoneLabel,
  getStrategyZoneLabelsByDownside,
  getStrategyZoneLabelsByUpside,

  getFundStatsColumns,
  getTableColumns,
  getStatLabels,
  getStatTooltips,
  getTableColSpans,
  tableClasses
}