import React, { useState, useEffect, useRef } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { graphql, Link } from 'gatsby'
import moment from 'moment'
import { CapGroupPageLayout, Tooltip, Disclosures } from '../../components'
import { getStrategyZoneLabel, getTableColumns, getStatLabels, getStatTooltips, getTableColSpans, tableClasses } from '../../labels'
import { formatNumber, isEmpty, groupBy } from '../../utilities'

const monthValue = {
  'Jan': 0,
  'Feb': 1,
  'Mar': 2,
  'Apr': 3,
  'May': 4,
  'Jun': 5,
  'Jul': 6,
  'Aug': 7,
  'Sep': 8,
  'Oct': 9,
  'Nov': 10,
  'Dec': 11
}

function getSortableFieldValue(field, obj) {
  var value = obj[field];

  if (field === 'resetMonth')
    return monthValue[Array.isArray(value) ? value[0] : value];

  if (['fundNav', 'fundReturn', 'referenceAssetReturn', 'fixedIncomeContribution', 'remainingDownsideCurrent', 'remainingDownsideBeforeCurrent', 'remainingUpsideBeforeCurrent', 'remainingUpsideCurrent', 'outcomePeriodDaysRemaining'].indexOf(field) > -1)
    return parseFloat(value);
    
  return value;
}

function filterFunds(funds, sortBy) {
  const filtered = funds;

  let intSortBy = sortBy;

  if (!sortBy)
    intSortBy = { field: 'sortIndex', dir: 'asc' };

  filtered.sort(function(a, b) {
    const av = getSortableFieldValue(intSortBy.field, a);
    const bv = getSortableFieldValue(intSortBy.field, b);

    if (av < bv)
      return -1 * (intSortBy.dir === 'asc' ? 1 : -1);
    if (av > bv)
      return 1 * (intSortBy.dir === 'asc' ? 1 : -1);

    if (a.sortIndex < b.sortIndex)
      return -1 * (intSortBy.dir === 'asc' ? 1 : -1);
    if (a.sortIndex > b.sortIndex)
      return 1 * (intSortBy.dir === 'asc' ? 1 : -1);
    
    return 0;
  });

  return groupBy(filtered, 'strategyId');
}

function getAsOfDate(funds) {
  return moment(Math.max(...(funds || []).map(f => moment(f.latestStats.date).valueOf())));
}

const SortArrow = ({ sortBy, field }) => {
  return sortBy?.field === field && <i className={`mi-icon mi-v-arrow ms-1 mi-3 mi-primary ${sortBy?.dir === 'asc' ? '' : 'mi-r-180'}`}></i>
}

const FundsPage = ({ data }) => {

  const funds = useRef(data.allFund.edges.map(e => e.node));
  const [sortBy, setSortBy] = useState(null);
  const [filtered, setFiltered] = useState(filterFunds(funds.current, sortBy));
  const [asOfDate, setAsOfDate] = useState(getAsOfDate(funds.current));

  const otherFunds = useRef(data.allOtherFund.edges.map(e => e.node));

  useEffect(() => {
    const grouped = filterFunds(funds.current, sortBy);  
    setFiltered(grouped);
    setAsOfDate(getAsOfDate(funds.current));
  }, [funds, sortBy]);

  function handleSortClick(field) {
    var newSortBy = { field: field, dir: 'asc' };

    if (sortBy && field === sortBy.field) {
      if (sortBy.dir === 'asc') newSortBy.dir = 'desc';
      if (sortBy.dir === 'desc') newSortBy = null;
    }

    setSortBy(newSortBy);
  }

  if (process.env.GATSBY_CAPGROUP_FUNDS !== 'true')
    return null;

  return <CapGroupPageLayout id="funds"  title="Product Table" className="page-funds">

    <Container>
      <div style={{ marginTop: '2rem', marginBottom: '2rem' }}>
        <Table responsive striped className="text-center">
          {Object.keys(filtered).map(k => {
            const columns = getTableColumns(filtered[k][0].downside, filtered[k][0].upside, filtered[k][0].strategySeries, process.env.GATSBY_EQUITY_COLUMN === 'true');
            const labels = getStatLabels(process.env.GATSBY_CLIENT, filtered[k][0].refAsset2, filtered[k][0].downside, filtered[k][0].upside, filtered[k][0].strategySeries);
            const tooltips = getStatTooltips(process.env.GATSBY_CLIENT, filtered[k][0].refAsset, filtered[k][0].refAsset2, filtered[k][0].downside, filtered[k][0].upside, filtered[k][0].strategySeries, true);
            const colSpans = getTableColSpans(filtered[k][0].downside, filtered[k][0].upside);

            return <tbody key={k}>
              <tr>
                <td className="text-start strategy-header" colSpan="14">
                  <Row>
                    <Col className="strategy-name">
                      {filtered[k][0].strategyName} <small>(Current/Net) <Tooltip tip={`Current figures are net of accrued ${filtered[k][0].strategySeries === 'capgroup' ? 'Option Allocation' : 'Outcome Period'} expenses to date. Net figures include ${filtered[k][0].strategySeries === 'capgroup' ? 'Option Allocation' : 'Outcome Period'} expenses yet to be incurred.`} /></small>
                    </Col>
                    <Col xs="auto">
                      <small>As of {asOfDate.format('MM/DD/yyyy')}</small>
                    </Col>
                  </Row>
                </td>
              </tr>

              <tr className="fund-header">
                {columns.map((col, ci) => {
                  const header = <th key={`header_${k}_${col}`} onClick={() => handleSortClick(col)} colSpan={colSpans[col]} className={`${tableClasses[col]}`} style={{cursor: 'pointer'}}>
                      <div className={`d-flex ${ci === 0 ? 'justify-content-start' : 'justify-content-center'} align-items-center`}>
                        <div>{labels[col]}</div>
                        <div><SortArrow sortBy={sortBy} field={col} size="1rem" /></div>
                      </div>
                    </th>;

                  if (!isEmpty(tooltips[col]))
                    return <Tooltip tip={tooltips[col]} key={`header_${k}_${col}`}>{header}</Tooltip>
                  else
                    return header;
                })}
              </tr>

              {filtered[k].map(f => <tr key={`fund_${f.fundId}`} className="fund-row">
                <td className="text-start"><Link to={`/funds/${f.fundId}`}><nobr>{f.resetMonthLabel}</nobr></Link></td>
                <td>{formatNumber(f.latestStats.fundNav, 'currency')}</td>
                <td>{formatNumber(f.latestStats.fundReturn, 'percent')}</td>
                <td><nobr>{formatNumber(f.latestStats.referenceAssetReturn, 'percent')}{!isEmpty(f.refAsset2) ? ' / ' + formatNumber(f.latestStats.secondaryReferenceAssetReturn, 'percent') : ''}</nobr></td>
                {columns.indexOf('equity') >= 0 && <td>{formatNumber(f.latestStats.equity, 'percent')}</td>}
                <td><nobr>{f.latestStats.strategyZone !== null ? getStrategyZoneLabel(f.latestStats.strategyZone, f) : '-'}</nobr></td>
                <td colSpan={colSpans.remainingDownsideCurrent}><nobr>{formatNumber(f.latestStats.remainingDownsideCurrent, 'percent')} / {formatNumber(f.latestStats.remainingDownsideNet, 'percent')}</nobr></td>
                {f.downside === 'Buffer' && f.upside !== 'Trigger' &&  <td><nobr>{formatNumber(f.latestStats.remainingDownsideBeforeCurrent, 'percent') } / {formatNumber(f.latestStats.remainingDownsideBeforeNet, 'percent')}</nobr></td>}
                {(f.upside === 'Spread' || f.upside === 'Trigger') && <td><nobr>{formatNumber(f.latestStats.remainingUpsideBeforeCurrent, 'percent')} / {formatNumber(f.latestStats.remainingUpsideBeforeNet, 'percent')}</nobr></td>}
                <td colSpan={colSpans.remainingUpsideCurrent}><nobr>{formatNumber(f.latestStats.remainingUpsideCurrent, 'percent')} / {formatNumber(f.latestStats.remainingUpsideNet, 'percent')}</nobr></td>
                <td><nobr>{formatNumber(f.latestStats.remainingTimeValueCurrent, 'percent')} / {formatNumber(f.latestStats.remainingTimeValueNet, 'percent')}</nobr></td>
                <td>{formatNumber(f.latestStats.outcomePeriodDaysRemaining)}</td>
              </tr>)}
            </tbody>})}
        </Table>

        {otherFunds.current && otherFunds.current.length > 0 &&
          <div className="other-funds mt-4">
            <h2>Other Funds</h2>
            {otherFunds.current.map(f => <div key={`otherfund_${f.fundId}`}><Link to={`/funds/${f.fundId}`}>{f.name}</Link></div>)}
          </div>
        }

        <Disclosures isProductList isCapGroup />
      </div>
    </Container>
  </CapGroupPageLayout>
}

export const query = graphql`
  {
    allFund(filter: {strategySeries: {eq: "capgroup"}}) {
      edges {
        node {
          fundId,
          strategyId,
          strategyName,
          strategyDescription,
          strategySeries,
          resetMonth,
          resetMonthLabel,
          refAsset,
          refAsset2,
          frequency,
          downside,
          upside,
          startingUpsideRate,
          startingDownsideRate,
          cusip,
          inceptionDate,
          startDate,
          endDate,
          sortIndex,
          initialStats {
            date
            fundNav
            fundReturn
            referenceAssetReturn
            secondaryReferenceAssetReturn
            equity
            fixedIncomeContribution
            strategyZone
            secondaryStrategyZone
            remainingTimeValueCurrent
            remainingTimeValueNet
            remainingUpsideCurrent
            remainingUpsideNet
            remainingUpsideBeforeCurrent
            remainingUpsideBeforeNet
            remainingDownsideBeforeCurrent
            remainingDownsideBeforeNet
            remainingDownsideCurrent
            remainingDownsideNet
            outcomePeriodDaysRemaining
          }
          latestStats {
            date
            fundNav
            fundReturn
            referenceAssetReturn
            secondaryReferenceAssetReturn
            equity
            fixedIncomeContribution
            strategyZone
            secondaryStrategyZone
            remainingTimeValueCurrent
            remainingTimeValueNet
            remainingUpsideCurrent
            remainingUpsideNet
            remainingUpsideBeforeCurrent
            remainingUpsideBeforeNet
            remainingDownsideBeforeCurrent
            remainingDownsideBeforeNet
            remainingDownsideCurrent
            remainingDownsideNet
            outcomePeriodDaysRemaining
          }
        }
      }
    }

    allOtherFund {
      edges {
        node {
          fundId,
          name,
          description,
          cusip,
          inceptionDate,
          sortIndex,
          initialStats {
            date
            fundNav
          }
          latestStats {
            date
            fundNav
          }
        }
      }
    }
  }
`

export default FundsPage
